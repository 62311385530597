import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import useImage from '@hooks/useImage'
import { Layout } from '@components/organisms'
import { useTheme } from "@mui/material/styles"
import useMediaQuery from '@mui/material/useMediaQuery'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PageNotFound = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const image = useImage('camera')

    const handleClick = () => {
        window.history.back()
    }

    return (
        <Layout seo={{ title: 'Página não encontrada', description: 'Página não encontrada' }}>
            <Grid container sx={ styles.background } alignItems='center' justify='space-between' flexDirection={ isMobile ? 'row-reverse' : 'row' }>
                <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                    <Typography color='primary' variant='h3' sx={ styles.title }>Opss 404!</Typography>
                    <Typography color='secondary' variant='h4' gutterBottom>Página não encontrada</Typography>
                    <Typography color='textSecondary' variant='h6'>
                        Acabou o filme e não conseguimos revelar a página que você estava procurando.
                    </Typography>
                    <Button startIcon={<KeyboardBackspaceRoundedIcon />} variant='outlined' onClick={ handleClick }
                        sx={ styles.button } size={ isMobile ? 'small' : 'medium' }
                    >
                        Voltar
                    </Button>
                </Grid>
                <Grid item xs={ 12 } sm={ 8 } md={ 6 }>
                    <GatsbyImage image={ getImage(image) } alt='404' itemProp='image'/>
                </Grid>
            </Grid>
        </Layout>
    )
}

const styles = {
    background: {
        minHeight: '100vh',
        padding: {
            md: '6vw',
            xs: 3
        }
    },
    title: {
        fontWeight: 'bold',
        mb: 1
    },
    button: {
        mt: 2
    },
}

export default PageNotFound